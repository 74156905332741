<template>
  <div>
    <v-icon
      @click="$router.replace({name:'homepage'})"
    >
      mdi-home
    </v-icon>
    Neplatný token
  </div>
</template>

<script>
export default {
  name: 'InvalidSignature'
}
</script>

<style scoped>

</style>
